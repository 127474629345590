<template>
  <div id="user-list">
    <!-- app drawer -->
    <voucher-template-aside
      v-if="formReady"
      v-model="sidebarActive"
      :resource="resource"
      @changed="loadVoucherTemplates(); sidebarActive = false"
    ></voucher-template-aside>

    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <v-row>
        <v-col cols="6"></v-col>
        <v-col
          cols="6"
          class="text-right"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="addNewResoure"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Template</span>
          </v-btn>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="voucherTemplateListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- Nicename -->
        <template #[`item.key`]="{item}">
          <span>
            {{ item.nicename }}
          </span>
        </template>

        <!-- Merchant -->
        <template #[`item.merchant`]="{item}">
          <span>
            {{ t(item.merchant.name) }}
          </span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                link
                @click="setVoucherTemplate(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Update</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
mdiDotsVertical,
mdiPencil,
mdiPlus,
} from '@mdi/js'
import { ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'

import { t } from '@/plugins/i18n'
import VoucherTemplateAside from '../voucher-template-resource/VoucherTemplateAside.vue'

import useVoucherTemplateList from './useVoucherTemplateList'

export default {
  components: {
    VoucherTemplateAside,
  },
  setup() {
    const {
      voucherTemplateListTable,
      tableColumns,
      searchQuery,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadVoucherTemplates,
      updateAllVoucherTemplates,
    } = useVoucherTemplateList()

    const sidebarActive = ref(false)
    const formReady = ref(true)
    const resource = ref(false)

    const rootVoucherTemplateName = voucherTemplates => {
      const voucherTemplate = voucherTemplates.find(c => !c.parent_id)

      return voucherTemplate ? t(voucherTemplate.name) : ''
    }

    // const voucherTemplateegoriesOptions = commonStore.state.voucherTemplateegories.map(voucherTemplate => ({ title: field(voucherTemplate.name), value: voucherTemplate.id }))

    const setVoucherTemplate = voucherTemplate => {
      resource.value = voucherTemplate
      sidebarActive.value = true
      formReady.value = false
      setTimeout(() => {
        formReady.value = true
      })
    }

    const addNewResoure = () => {
      resource.value = null
      formReady.value = false
      sidebarActive.value = true
      setTimeout(() => {
        formReady.value = true
      })
    }

    return {
      voucherTemplateListTable,
      tableColumns,
      searchQuery,
      totalListTable,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      sidebarActive,
      formReady,
      resource,
      selectedRows,
      avatarText,
      loadVoucherTemplates,
      setVoucherTemplate,
      addNewResoure,
      updateAllVoucherTemplates,
      rootVoucherTemplateName,

      t,

      // icons
      icons: {
        mdiPencil,
        mdiDotsVertical,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
